<template>
	<div>
		<div class="header">
			<div class="cartel_top">
				<div class="top top_mafi f-left">
					<div class="util">
						<button
							@click="$emit('goBack')"
							class="arrow_white"
						>
							<i class="icon-arrow-left"></i>
						</button>
					</div>
					<div class="page_tit_area">
						<h2 class="page_tit"> {{ $language.subscribe.title_subscribe_plane_management }} <!-- 구독 플랜 관리 --></h2>
						<span class="page_subtit">{{  item_cartel.cartl_name }}</span>
					</div>
				</div>
			</div>
		</div>
		<!-- //고정해더 -->
		<div class="section_wrap pt-80">
			<div class="subindex_head">
				<div class="container">
					<div class="subindex_box_1">
						<ul
							v-if="plane_list.length > 0"
						>
							<li
								v-for="(plane, p_index) in plane_list"
								:key="'plane_' + p_index"
								class="justify-space-between mb-10 cursor-pointer"
								@click="toDetail(plane)"
							>
								<div class="img-box-100 mr-10 radius-20 overflow-hidden"><img :src="plane.img_physl_path" @error="$bus.$emit('onErrorImage', $event)" class="object-cover"/></div>
								<div class="flex-1 flex-column justify-center size-px-14 text-left">
									<div class="font-weight-500">{{ plane.subscrp_plan_name }}</div>
									<div class="mt-10">{{ plane.subscrp_amount | makeComma }}</div>
									<div class="mt-10 color-gray">{{ plane.subscrp_plan_state_name }}</div>
								</div>
								<div class="flex-column justify-center">
									<button
									><v-icon>mdi-chevron-right</v-icon></button>
								</div>
							</li>
						</ul>
						<Empty
							v-else

							:text="$language.add.txt_add_subscribe"
							class="size-px-14"
						></Empty>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Empty from "@/view/Layout/Empty";
export default {
	name: 'SubscribePlane'
	,
	components: {Empty},
	props: ['user']
	, data: function(){
		return {
			program: {
				name: this.$language.notice.add
				, title: this.$language.notice.add
				, not_footer: true
				, not_header: true
				, type: 'cartel_sub'

			}
			, item_cartel: {}
			, items_plane: [
				{ subscrp_plan_number: 1, subscrp_plan_name: '루비', subscrp_amount: 5000, is_use: true, img_physl_path: ''}
				, { subscrp_plan_number: 2, subscrp_plan_name: '브론즈', subscrp_amount: 15000, is_use: true, img_physl_path: ''}
				, { subscrp_plan_number: 3, subscrp_plan_name: '실버', subscrp_amount: 25000, is_use: false, img_physl_path: ''}
				, { subscrp_plan_number: 4, subscrp_plan_name: '골드', subscrp_amount: 35000, is_use: true, img_physl_path: ''}
				, { subscrp_plan_number: 5, subscrp_plan_name: '다이아몬드', subscrp_amount: 45000, is_use: true, img_physl_path: ''}
			]
		}
	}
	, computed: {
		plane_list: function(){
			return this.items_plane.filter( (item) => {
				if(item.is_use){
					item.is_use_name = '판매중'
				}else{
					item.is_use_name = '미판매'
				}
				return item
			})
		}
	}
	, methods: {
		getCartel: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_cartel_info
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
						, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
						, post_list_page: { page_number: 1, pagerecnum: 10}
					}
					, type: true
				})

				if(result.success){
					this.item_cartel = result.data
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}

		, getPlaneList: async function(){
			try{
				this.$bus.$emit('on', true)
				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_subscribe_plane_list
					, data: {
						member_number: this.user.member_number
						, cartl_number: this.$route.params.idx
					}
					, type: true
				})

				if(result.success){
					this.items_plane = result.data.subscrp_plan_list
				}else{
					throw result.message
				}
			}catch (e) {
				console.log(e)
				this.$bus.$emit('notify', { type: 'error', message: e})
			}finally {
				this.$bus.$emit('on', false)
			}
		}
		, toDetail: function(item){
			this.$bus.$emit('to', { name: 'SubscribePlaneDetail', params: { idx: this.$route.params.idx, p_id: item.subscrp_plan_number}})
		}
	}
	, async created() {
		this.$bus.$emit('onLoad', this.program)
		await this.getCartel()
		await this.getPlaneList()
	}
}
</script>

<style>
.color-primary { color: var(--blue01) !important;}

</style>